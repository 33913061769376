<template>
  <div class="article-list">
    <!-- 筛选表单 -->
    <a-form-model :form="form" layout="inline" @submit="onSearch">
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-form-model-item label="标题:" prop="keyword">
            <a-input v-model="form.keyword" placeholder="请输入" allowClear />
          </a-form-model-item>
          <a-form-model-item label="发布状态:" prop="is_published">
            <a-select
              v-model="form.is_published"
              placeholder="请选择"
              allowClear
              style="width: 120px"
            >
              <a-select-option value="true">已发布</a-select-option>
              <a-select-option value="false">未发布</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="审核状态:" prop="status">
            <a-select
              v-model="form.status"
              placeholder="请选择"
              allowClear
              style="width: 120px"
            >
              <a-select-option
                v-for="option in statusOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="分类:" prop="category">
            <a-select
              v-model="form.category"
              placeholder="请选择"
              allowClear
              style="width: 120px"
            >
              <a-select-option
                v-for="option in categoryList"
                :key="option._id"
                :value="option._id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" html-type="submit">搜索</a-button>
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-button type="primary" @click="onAddClick">新增</a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <!-- 文章列表 -->
    <a-table
      class="list"
      :columns="columns"
      :dataSource="data"
      :loading="loading"
      :pagination="pagination"
      :rowKey="(record) => record._id"
      @change="onTableChange"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="text, record, index">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <!-- 标题 -->
      <template slot="articleTitle" slot-scope="text, record">
        <a
          :href="`https://pinews.app/post/${record._id}`"
          target="_blank"
          style="color: #1890ff; font-weight: bold"
        >
          <a-icon type="link" />
        </a>
        {{ text }}
      </template>
      <!-- 发布状态 -->
      <template slot="is_published" slot-scope="text">
        <a-tag v-if="text" color="green">已发布</a-tag>
        <a-tag v-else color="orange">未发布</a-tag>
      </template>
      <!-- 审核状态 -->
      <template slot="status" slot-scope="text">
        <a-tag v-if="text === ARTICLE_STATUS.APPROVED.value" color="green">
          已审核
        </a-tag>
        <a-tag v-if="text === ARTICLE_STATUS.IN_REVIEW.value" color="orange">
          待审核
        </a-tag>
        <a-tag v-if="text === ARTICLE_STATUS.NOT_APPROVED.value" color="red">
          未通过
        </a-tag>
      </template>
      <!-- 操作 -->
      <template slot="action" slot-scope="text, record">
        <a-space>
          <a
            v-if="record.status === ARTICLE_STATUS.IN_REVIEW.value"
            type="link"
            @click="onReviewClick(record)"
          >
            审核
          </a>
          <a v-if="record.create_by" type="link" @click="onEditClick(record)">
            编辑
          </a>
          <a-popconfirm
            v-if="record.is_published"
            title="确定撤回该文章？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="onDisableClick(record)"
          >
            <a type="link" style="color: #f30">取消发布</a>
          </a-popconfirm>
          <a-popconfirm
            v-else
            title="确定发布该文章？"
            ok-text="Yes"
            cancel-text="No"
            :disabled="record.status !== ARTICLE_STATUS.APPROVED.value"
            @confirm="onEnableClick(record)"
          >
            <a
              type="link"
              :disabled="record.status !== ARTICLE_STATUS.APPROVED.value"
            >
              发布
            </a>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script>
import { ARTICLE_STATUS } from '@/config/const.js';

export default {
  name: 'ArticleList',
  data() {
    return {
      ARTICLE_STATUS,
      loading: false,
      columns: [
        {
          title: '序号',
          width: 80,
          align: 'center',
          scopedSlots: { customRender: 'index' },
        },
        {
          title: '标题',
          dataIndex: 'title',
          scopedSlots: { customRender: 'articleTitle' },
        },
        {
          title: '发布状态',
          dataIndex: 'is_published',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'is_published' },
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '作者',
          dataIndex: 'author.pi_username',
          align: 'center',
          customRender: (text, record) => {
            if (record.author) {
              return '@' + text;
            } else {
              return record.create_by.username;
            }
          },
        },
        {
          title: '阅读量',
          dataIndex: 'view_count',
          width: 100,
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'create_at',
          width: 220,
          align: 'center',
          customRender: (text) =>
            this.$dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          title: '更新者',
          dataIndex: 'update_by.username',
          // width: 120,
          align: 'center',
          customRender: (text) => text || '-',
        },
        {
          title: '更新时间',
          dataIndex: 'update_at',
          width: 220,
          align: 'center',
          customRender: (text) =>
            text ? this.$dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-',
        },
        {
          title: '操作',
          width: 140,
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      form: {
        keyword: undefined,
        tag: undefined,
        category: undefined,
        author: undefined,
        create_by: undefined,
        is_published: undefined,
      },
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      categoryList: [],
    };
  },
  computed: {
    statusOptions() {
      return Object.values(ARTICLE_STATUS);
    },
  },
  mounted() {
    this.getCategoryList();
    this.getList();
  },
  methods: {
    onSearch(e) {
      e.preventDefault();
      this.getList();
    },
    onAddClick() {
      this.$router.push('/article/add');
    },
    // 获取列表
    async getList() {
      this.loading = true;
      const { err, res } = await this.$api.article.list({
        pageIndex: this.pagination.current - 1,
        pageSize: this.pagination.pageSize,
        ...this.form,
      });
      if (!err) {
        this.data = res.result.list;
        this.pagination.total = res.result.total;
      }
      this.loading = false;
    },
    // 获取分类列表
    async getCategoryList() {
      const { err, res } = await this.$api.article.categoryList();
      if (!err) {
        this.categoryList = res.result.list;
      }
    },
    // 撤回发布
    async onDisableClick(record) {
      this.loading = true;
      const { err, res } = await this.$api.article.revoke({ _id: record._id });
      if (!err) {
        this.$message.success(res.msg);
        this.getList();
      }
      this.loading = false;
    },
    // 发布
    async onEnableClick(record) {
      this.loading = true;
      const { err, res } = await this.$api.article.publish({ _id: record._id });
      if (!err) {
        this.$message.success(res.msg);
        this.getList();
      }
      this.loading = false;
    },
    // 编辑
    async onEditClick(record) {
      this.$router.push({
        path: '/article/edit/',
        query: {
          _id: record._id,
        },
      });
    },
    // 审核
    onReviewClick(record) {
      this.$router.push({
        path: '/article/review/',
        query: {
          _id: record._id,
        },
      });
    },
    // 分页
    onTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  margin-top: 20px;
}
</style>
